import { IconButton } from '@rmwc/icon-button';
import { Ripple } from '@rmwc/ripple';
import {
  AddButton,
  ArchiveButtonWithConfirmation,
  Checkbox,
  ContextMenu,
  DeleteButtonWithConfirmation,
  Divider,
  EmptyList,
  Fade,
  ListCreationDialog,
  ShareButtonWithConfirmation,
} from 'components';
import { breakpoints, ROUTES } from 'constant';
import { css } from 'emotion';
import { AnimatePresence, motion, TargetAndTransition, useCycle } from 'framer-motion';
import { useLongPress } from 'hooks/use-long-press';
import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HideAt, ShowAt } from 'react-with-breakpoints';
import { List } from 'types/List';
import { encodeList } from 'utils';
import { useDatabaseChange } from 'hooks/use-database-change';
import { ListItem } from './ListItem';
import { createList, deleteLists, getLists, updateList } from 'database/List';

const styles = css`
  padding: var(--spacing-unit);
  width: 100%;
  overflow: hidden;

  .subheader {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 var(--spacing-unit);

    > .count {
      display: flex;
      align-items: center;
      > button.mdc-icon-button {
        height: 36px;
        width: 36px;
        margin-right: var(--spacing-unit);
        font-size: 1.2rem;
        padding: 0;
      }
    }

    > .mdc-form-field {
      margin-left: auto;
    }
  }

  > .lists {
    padding: 0 var(--spacing-unit);

    > ul {
      padding-top: var(--spacing-unit);
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: min-content;
      gap: var(--spacing-unit-2) var(--spacing-unit-2);
      margin-bottom: 80px;

      @media (min-width: ${breakpoints.md}px) {
        grid-template-columns: repeat(3, 1fr);
      }

      > li {
        box-shadow: var(--box-shadow);
        border: 1px solid var(--secondary);
        border-radius: 4px;
        background-color: var(--secondary);
        width: 100%;
        cursor: pointer;

        &.selected {
          border-color: var(--accent);
        }
      }
    }
  }
`;

const itemVariants = {
  enter: (position: number): TargetAndTransition => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: position * 0.03,
      y: { stiffness: 1000, velocity: -100 },
    },
  }),
  exit: {
    y: 30,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const AllLists: React.FC = () => {
  const { t } = useTranslation();
  const [showArchived, setShowArchived] = useState<boolean>(() => {
    const savedPref = localStorage.getItem('lists.showArchived');
    try {
      return savedPref ? JSON.parse(savedPref) : false;
    } catch (e) {
      return false;
    }
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [lists, setLists] = useState<List[]>([]);
  const [selectedLists, setSelectedLists] = useState<string[]>([]);
  const [open, setOpen] = useCycle(false, true);
  const history = useHistory();
  const longPressProps = useLongPress((e: React.TouchEvent<HTMLElement>) => {
    let target = e.target as HTMLElement;
    while (target.parentElement && !target.getAttribute('data-id')) {
      target = target.parentElement!;
    }
    const id = target.getAttribute('data-id');
    if (id) {
      window.navigator.vibrate(50);
      setSelectedLists(old => {
        if (old.includes(id)) {
          return old.filter(v => v !== id);
        } else {
          return old.concat(id);
        }
      });
    }
  });

  const load = useCallback((): void => {
    getLists()
      .then(results => {
        if (!showArchived) {
          setLists(results.filter(r => !r.archived));
        } else {
          setLists(results);
        }
      })
      .finally(() => setLoading(false));
  }, [showArchived]);

  useDatabaseChange(load);

  const handleNew = useCallback(
    (name?: string, color?: string) => {
      setOpen(0);
      createList(name, color).then(list => {
        if (list) {
          history.push(ROUTES.LIST(String(list.id)));
        }
      });
    },
    [history, setOpen],
  );

  const handleMultiDelete = useCallback(() => {
    deleteLists(selectedLists).then(() => setSelectedLists([]));
  }, [selectedLists]);

  const handleSingleDelete = useCallback((id: string) => {
    deleteLists([id]).then(() => setSelectedLists(old => old.filter(i => i !== id)));
  }, []);

  const handleShare = useCallback(() => {
    const encodedList = encodeList(lists.find(l => l.id === selectedLists[0])!);
    const link = `https://todo.khelifa.dev/?share=${encodedList}`;
    navigator.clipboard.writeText(link).then(() => {
      // TODO notify user
    });
    return link;
  }, [lists, selectedLists]);

  const handleMultiArchive = useCallback(() => {
    Promise.all(selectedLists.map(selectedListId => updateList(selectedListId, { archived: true }))).then(() =>
      setSelectedLists([]),
    );
  }, [selectedLists]);

  const handleSingleArchive = useCallback((id, value) => {
    updateList(id, { archived: value }).then(() => setSelectedLists([]));
  }, []);

  const clearSelectedLists = useCallback(() => {
    setSelectedLists([]);
  }, []);

  const handleShowArchived = useCallback(() => {
    setShowArchived(old => {
      localStorage.setItem('lists.showArchived', String(!old));
      return !old;
    });
  }, []);

  return (
    <Fade className={styles}>
      <ListCreationDialog onNew={handleNew} onCancel={setOpen} open={open} />
      <AnimatePresence exitBeforeEnter>
        {selectedLists.length === 0 && <AddButton key="add" onClick={setOpen} />}
        {selectedLists.length === 1 && (
          <ShareButtonWithConfirmation
            key="share"
            linkCallback={handleShare}
            confirmationTitle={t('shareLink')}
            onClose={clearSelectedLists}
          />
        )}
        {selectedLists.length !== 0 && [
          <DeleteButtonWithConfirmation
            key="delete"
            callback={handleMultiDelete}
            confirmationTitle={t('deleteListConfirmationTitle')}
            confirmationContent={t('deleteListConfirmationContent', { count: selectedLists.length })}
          />,
          <ArchiveButtonWithConfirmation
            key="archive"
            callback={handleMultiArchive}
            confirmationTitle={t('archiveListConfirmationTitle')}
            confirmationContent={t('archiveListConfirmationContent', { count: selectedLists.length })}
          />,
        ]}
      </AnimatePresence>
      <div className="subheader">
        {lists.length === 0 ? (
          <span className="count">{t('emptyLists')}</span>
        ) : (
          <span className="count">
            {selectedLists.length !== 0 && <IconButton icon="clear" onClick={clearSelectedLists} />}
            {t(selectedLists.length ? 'selectedList' : 'listCount', { count: selectedLists.length || lists.length })}
          </span>
        )}
        <Checkbox name="showArchived" checked={showArchived} onChange={handleShowArchived} label={t('showArchive')} />
      </div>
      <Divider />
      <div className="lists">
        {!loading && (
          <AnimatePresence exitBeforeEnter>
            {lists.length === 0 && <EmptyList />}
            {lists.length !== 0 && (
              <motion.ul animate="enter" initial="exit" exit="exit">
                <AnimatePresence>
                  {lists.length !== 0 &&
                    lists.map((list, index) => (
                      <Fragment key={list.id}>
                        <HideAt breakpoint="largeAndAbove">
                          <Ripple
                            {...longPressProps}
                            className={selectedLists.includes(String(list.id)) ? 'selected' : undefined}
                          >
                            <motion.li variants={itemVariants} layout="position" custom={index} data-id={list.id}>
                              <ListItem list={list} />
                            </motion.li>
                          </Ripple>
                        </HideAt>

                        <ShowAt breakpoint="largeAndAbove">
                          <ContextMenu
                            className={selectedLists.includes(String(list.id)) ? 'selected' : undefined}
                            tag={motion.li}
                            variants={itemVariants}
                            layout="position"
                            custom={index}
                            data-id={list.id}
                            menu={[
                              {
                                label: t(selectedLists.includes(String(list.id)) ? 'unselect' : 'select'),
                                action: () =>
                                  setSelectedLists(old => {
                                    const id = String(list.id);
                                    if (old.includes(id)) {
                                      return old.filter(v => v !== id);
                                    } else {
                                      return old.concat(id);
                                    }
                                  }),
                              },
                              selectedLists.length < 1
                                ? {
                                    label: list.archived ? t('unarchive') : t('archive'),
                                    action: () => handleSingleArchive(list.id, !list.archived),
                                  }
                                : null,
                              {
                                label: t('delete'),
                                action: () => handleSingleDelete(list.id),
                              },
                            ]}
                          >
                            <ListItem list={list} />
                          </ContextMenu>
                        </ShowAt>
                      </Fragment>
                    ))}
                </AnimatePresence>
              </motion.ul>
            )}
          </AnimatePresence>
        )}
      </div>
    </Fade>
  );
};
