import { css, cx } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { ColorCircle } from 'components';

interface Props {
  color: string;
  colors: string[];
  onSelect: (color: string) => void | undefined;
}

const styles = css`
  height: 48px;
  width: 48px;
  flex-shrink: 0;
  border-radius: 4px;

  > ul {
    border-radius: 4px;
    height: 48px;
    width: 48px;
    overflow: hidden;
    transition: height 200ms ease-in, background-color 200ms ease-in, box-shadow 200ms ease-in;

    > li {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.open {
    overflow: visible;

    > ul {
      height: 288px;
      background-color: var(--secondary);
      box-shadow: var(--box-shadow);
    }
  }
`;

export const ColorPicker: React.FC<Props> = ({ color, colors, onSelect }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);

  const handleColorPick = useCallback(
    (color: string) => {
      const start = color.indexOf('(') + 1;
      const end = color.indexOf(')');
      onSelect(color.slice(start, end));
      setOpen(false);
    },
    [onSelect],
  );

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      let target = event.target as HTMLElement;
      while (target.id !== 'color-picker' && target.parentElement !== null) {
        target = target.parentElement;
      }
      if (target.id !== 'color-picker') {
        setOpen(false);
      }
    };

    document.addEventListener('click', listener);
    return () => document.removeEventListener('click', listener);
  }, []);

  return (
    <div className={cx(styles, open && 'open')} id="color-picker">
      <ul>
        <li>
          <ColorCircle color={`var(${color})`} onClick={open ? handleClose : handleOpen} />
        </li>
        {colors.map(c => (
          <li key={c}>
            <ColorCircle color={`var(${c})`} onClick={handleColorPick} />
          </li>
        ))}
      </ul>
    </div>
  );
};
