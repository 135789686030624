import { Nav } from 'components/Nav/Nav';
import { ScrollView } from 'components/ScrollView/ScrollView';
import { css } from 'emotion';
import React from 'react';

const styles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  background-color: var(--primary);
  overflow: hidden;
  border-right: 1px solid;
  border-right-color: rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
`;

export const Sidebar: React.FC = () => {
  return (
    <aside className={styles}>
      <ScrollView>
        <Nav />
      </ScrollView>
    </aside>
  );
};
