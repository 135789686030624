import React, { forwardRef, useRef } from 'react';
import { InputContainer } from './InputContainer';

export interface InputProps {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  icon?: string;
  label: string;
  name: string;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void | undefined;
  onFocus?: (e: React.FormEvent<HTMLInputElement>) => void | undefined;
  onChange?: (e: React.ChangeEvent<any>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
  value?: string;
  autoFocus?: boolean;
}

export const Input = forwardRef(
  (
    {
      children,
      className,
      defaultValue,
      disabled,
      error,
      icon,
      label,
      name,
      onChange,
      onBlur,
      readOnly,
      required,
      value,
      autoFocus,
      ...props
    }: InputProps,
    parentRef: any,
  ) => {
    const internalRef = useRef(null);

    let ref = parentRef;
    if (!ref) {
      ref = internalRef;
    }

    return (
      <InputContainer
        className={className}
        label={label}
        error={error}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        icon={icon}
        ref={ref}
      >
        <input
          autoFocus={autoFocus}
          name={name}
          ref={ref}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />
        {children}
      </InputContainer>
    );
  },
);

Input.displayName = 'Input';
