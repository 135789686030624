import Dexie from 'dexie';
import 'dexie-observable';
import { List } from 'types/List';

class TodoListDB extends Dexie {
  lists: Dexie.Table<List, string>;

  constructor() {
    super('TodoListsDB');
    this.version(1).stores({
      lists: '&id, name, creationDate, archived, color, *tasks',
    });
    this.lists = this.table('lists') as Dexie.Table<List, string>;
  }
}

export default new TodoListDB();
