import { Icon } from '@rmwc/icon';
import { Menu, withPortal, Dialog, DialogContent, DialogActions, Button, ColorPicker } from 'components';
import { breakpoints, ROUTES } from 'constant';
import { css } from 'emotion';
import { AnimatePresence, motion, MotionValue } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { HideAt } from 'react-with-breakpoints';
import { List } from 'types/List';
import { encodeList, includesOneOf, LIST_COLORS } from 'utils';
import { deleteLists, getList, updateList } from 'database/List';
import { useDatabaseChange } from 'hooks/use-database-change';

interface Props {
  yMotion?: MotionValue<number>;
}

const colors = Array.from(Array(LIST_COLORS.length)).map((_, i) => {
  const str = '--list-color';
  return i === 0 ? str : str + '-' + (i + 1);
});

const styles = css`
  height: 100%;
  display: flex;
  padding: var(--spacing-unit-2);

  > .status {
    position: absolute;
    right: var(--spacing-unit-2);
    top: var(--spacing-unit-2);
    color: white;
    display: inline-flex;
    user-select: none;

    @media (min-width: ${breakpoints.md}px) {
      right: var(--spacing-unit-2);
      top: var(--spacing-unit-2);
    }

    > li {
      & + li {
        margin-left: var(--spacing-unit);
      }
      > * {
        width: auto;
        font-weight: 600;
        font-size: 2rem;
      }
    }
  }

  > .title {
    width: 100%;
    align-self: flex-end;
    display: flex;

    button.mdc-icon-button {
      font-size: 2rem !important;
      padding: 0;
    }

    > input {
      background-color: transparent;
      border: none;
      color: #ffffff;
      font-size: 2rem;
      font-weight: 600;
      width: 100%;
      padding: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: ${breakpoints.sm}px) {
        font-size: 2.5rem;
      }

      &::placeholder {
        color: #ffffff;
      }
    }
  }
`;

const linkStyles = css`
  overflow-wrap: break-spaces;
  word-wrap: break-spaces;
  word-break: break-all;
  hyphens: none;
`;

const variants = {
  enter: { scale: 1, opacity: 1 },
  exit: { scale: 0, opacity: 0 },
};

export const ListHeader: React.FC<Props> = ({ yMotion }) => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const [list, setList] = useState<List>();
  const [showTitle, setShowTitle] = useState<boolean>(false);
  const [shareState, setShareState] = useState({ showShare: false, link: '' });
  const ref = useRef<HTMLInputElement>(null);

  const load = useCallback(() => {
    getList(id).then(setList);
  }, [id]);

  const shouldReload = useCallback(
    (changes: any[]): boolean => {
      const item = changes.find(change => change.key === id);
      return item && includesOneOf(Object.keys(item.mods), ['name', 'color', 'archived', 'tasks']);
    },
    [id],
  );

  useDatabaseChange(load, shouldReload);

  useEffect(() => {
    if (yMotion) {
      yMotion.onChange(y => setShowTitle(y > 140));
    }
  }, [yMotion]);

  const handleNamechange = useCallback(
    e => {
      if (id) {
        updateList(id, { name: e.target.value }).then(setList);
      }
    },
    [id],
  );

  const focus = useCallback(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleArchive = useCallback(() => {
    if (list) {
      updateList(list.id, { archived: !list.archived }).then();
    }
  }, [list]);

  const handleDelete = useCallback(() => {
    if (list) {
      history.replace(ROUTES.LISTS());
      setTimeout(() => deleteLists([list.id]), 300);
    }
  }, [list, history]);

  const handleShare = useCallback(() => {
    if (list) {
      const encodedList = encodeList(list);
      const link = `https://todo.khelifa.dev/?share=${encodedList}`;
      navigator.clipboard.writeText(link).then(() => {
        // TODO notify user
      });
      setShareState({ showShare: true, link });
    }
  }, [list]);

  const closeSharePopup = useCallback(() => {
    setShareState(old => ({ ...old, showShare: false }));
  }, []);

  const handleColorChange = useCallback(
    (color: string) => {
      updateList(list!.id, { color }).then();
    },
    [list],
  );

  if (!list) {
    return null;
  }

  const complete = list.tasks.length !== 0 && !list.tasks.find(t => !t.done);
  const archived = list.archived;

  return (
    <motion.div className={styles} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <ul className="status">
        <AnimatePresence>
          {archived && (
            <motion.li key="archive" variants={variants} animate="enter" exit="exit" initial="exit" layout="position">
              <Icon icon="archive" />
            </motion.li>
          )}
          {complete && (
            <motion.li key="complete" variants={variants} animate="enter" exit="exit" initial="exit" layout="position">
              <Icon icon="check" />
            </motion.li>
          )}
        </AnimatePresence>
      </ul>

      <HideAt breakpoint="largeAndAbove">
        <AnimatePresence>
          {showTitle && <TitleOnMenuBar key="title" title={list.name || t('nameList')} onClick={focus} />}
        </AnimatePresence>
      </HideAt>

      <div className="title">
        <input ref={ref} defaultValue={list.name} onBlur={handleNamechange} placeholder={t('nameList')} />
        <ColorPicker color={list.color} colors={colors.filter(c => c !== list.color)} onSelect={handleColorChange} />
        <Menu
          menu={[
            { label: t('share'), action: handleShare },
            { label: t(list.archived ? 'unarchive' : 'archive'), action: handleArchive },
            { label: t('delete'), action: handleDelete },
          ]}
        />
      </div>
      <Dialog open={shareState.showShare} id="share-list-dialog" title={t('shareLink')} onClose={closeSharePopup}>
        <DialogContent className={linkStyles}>{shareState.link}</DialogContent>
        <DialogActions>
          <Button label={t('ok')} onClick={closeSharePopup} />
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};

interface TitleProps {
  title: string;
  onClick?: () => void | undefined;
}

const titleStyles = css`
  position: absolute;
  left: 60px;
  top: 0;
  height: 56px;
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
  padding-right: var(--spacing-unit);
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
  z-index: 10;
  overflow: hidden;
`;

const titleVariants = {
  enter: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 2 } },
};

const innerTitleVariants = {
  enter: { x: 0 },
  exit: { x: -100, transition: { duration: 1, delay: 0.3 } },
};

const TitleOnMenuBar = withPortal('root')(({ title, onClick }: TitleProps) => {
  return (
    <motion.div className={titleStyles} exit="exit" animate="enter" initial="exit" variants={titleVariants}>
      <motion.span onClick={onClick} variants={innerTitleVariants}>
        {title}
      </motion.span>
    </motion.div>
  );
});
