import { css } from 'emotion';
import { motion } from 'framer-motion';
import React from 'react';

const styles = css`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 11;
  background-color: black;
  opacity: 0;
  top: 0;
  left: 0;
`;

const variants = {
  enter: {
    opacity: 0.6,
    transition: {
      duration: 0.25,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
      delay: 0.25,
    },
  },
};

export const SidebarBackdrop: React.FC<any> = props => {
  return <motion.div animate="enter" exit="exit" className={styles} variants={variants} {...props} />;
};
