import { css, cx } from 'emotion';
import React from 'react';

const styles = css`
  text-align: center;
  align-self: center;
  letter-spacing: 0.1rem;
  position: relative;
  user-select: none;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  transition: background-color ease-out 150ms;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &.today {
    color: var(--accent);
  }

  &.selected {
    color: inherit;
    background-color: var(--accent);
  }
`;

const indexStyles = (index: number): string => css`
  grid-column: ${(index % 7) + 1} / span 1;
`;

interface Props {
  index: number;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined;
}

export const CalendarCell: React.FC<Props> = ({ children, className, ...props }) => (
  <div className={cx(styles, indexStyles(props.index), className)} {...props}>
    <span>{children}</span>
  </div>
);
