import { css, cx } from 'emotion';
import React from 'react';

interface DialogContent {
  className?: string;
}

const styles = css`
  padding: var(--spacing-unit) var(--spacing-unit-3);
`;

export const DialogContent: React.FC<DialogContent> = ({ className, ...props }) => {
  return <div className={cx(styles, className)} {...props} />;
};
