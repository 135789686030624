export default {
  add: 'Ajouter',
  update: 'Modifier',
  subject: 'Sujet',
  done: 'Terminée',
  todo: 'À faire',
  cancel: 'Annuler',
  allLists: 'Toutes les listes',
  listName: 'Nom de la liste',
  nameList: 'Donner un nom à la liste',
  newList: 'Nouvelle liste',
  newTask: 'Nouvelle tâche',
  updateTask: 'Modifier la tâche',
  unnamedList: 'Liste anonyme',
  Sunday: 'Dimanche',
  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Dimanche',
  Sun: 'Dim',
  Mon: 'Lun',
  Tue: 'Mar',
  Wed: 'Mer',
  Thu: 'Jeu',
  Fri: 'Ven',
  Sat: 'Sam',
  Jan: 'Jan',
  Feb: 'Fev',
  Mar: 'Mar',
  Apr: 'Avr',
  May: 'Mai',
  Jun: 'Juin',
  Jul: 'Jui',
  Aug: 'Aou',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre',
  description: 'Description',
  deadline: 'Échéance',
  clear: 'Effacer',
  emptyList: 'Aucune tâche',
  emptyLists: "Vous n'avez rien à faire pour le moment!",
  listCount: '{{ count }} liste',
  listCount_plural: '{{ count }} listes',
  createdOn: 'Créée le {{ date }}',
  errorDialogTitle: 'Oups ! Une erreur est survenue',
  listNotFound: 'Impossible de charger la liste {{ id }}',
  delete: 'Supprimer',
  deleteListConfirmationTitle: 'Confirmation de suppression',
  deleteListConfirmationContent: 'Êtes-vous sûr(e) de vouloir supprimer cette liste ?',
  deleteListConfirmationContent_plural: 'Êtes-vous sûr(e) de vouloir supprimer ces {{ count }} listes ?',
  archiveListConfirmationTitle: 'Confirmation',
  archiveListConfirmationContent: 'Êtes-vous sûr(e) de vouloir archiver cette liste ?',
  archiveListConfirmationContent_plural: 'Êtes-vous sûr(e) de vouloir archiver ces {{ count }} listes ?',
  selectedList: '{{ count }} liste sélectionnée',
  selectedList_plural: '{{ count }} listes sélectionnées',
  select: 'Sélectionner',
  unselect: 'Désélectionner',
  archive: 'Archiver',
  unarchive: 'Désarchiver',
  shareLink: 'Lien de partage',
  showArchive: "Afficher l'archive",
  share: 'Partager',
};
