import { Dialog } from 'components/Dialog/Dialog';
import { DialogActions } from 'components/Dialog/DialogActions';
import { DialogContent } from 'components/Dialog/DialogContent';
import { useCycle } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArchiveButton } from './ArchiveButton';
import { Button } from './Button';

interface Props {
  callback: () => void | undefined;
  confirmationTitle: string;
  confirmationContent: string;
}

export const ArchiveButtonWithConfirmation: React.FC<Props> = ({
  callback,
  confirmationTitle,
  confirmationContent,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useCycle(false, true);

  return (
    <>
      <ArchiveButton onClick={setOpen} />
      <Dialog id="archive-confirm-dialog" open={open} title={confirmationTitle}>
        <DialogContent>{confirmationContent}</DialogContent>
        <DialogActions>
          <Button label={t('cancel')} onClick={setOpen} />
          <Button label={t('archive')} onClick={callback} />
        </DialogActions>
      </Dialog>
    </>
  );
};
