import { breakpoints } from 'constant';
import { css } from 'emotion';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

const styles = css`
  height: 100%;
  display: flex;
  padding: var(--spacing-unit-2);

  > .title {
    width: 100%;
    align-self: flex-end;

    > span {
      background-color: transparent;
      border: none;
      color: #ffffff;
      font-size: 2rem;
      font-weight: 600;
      width: 100%;
      padding: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: ${breakpoints.sm}px) {
        font-size: 2.5rem;
      }
    }
  }
`;

export const ListsHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <motion.div className={styles} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="title">
        <span>{t('allLists')}</span>
      </div>
    </motion.div>
  );
};
