import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';
import { initI18n } from 'i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'app';
import { _breakpoints } from 'constant';
import { BreakpointsProvider } from 'react-with-breakpoints';
import { AppProvider } from 'stores';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';

initI18n();

ReactDOM.render(
  <BrowserRouter>
    <AppProvider>
      <BreakpointsProvider breakpoints={_breakpoints}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BreakpointsProvider>
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
