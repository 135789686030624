import { Icon } from '@rmwc/icon';
import { ROUTES } from 'constant';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { List } from 'types/List';

interface Props {
  list: List;
}

const styles = css`
  touch-action: none;
  user-select: none;
  height: 100%;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-unit);

    > div {
      &:first-child {
        display: flex;
        align-items: center;
        margin-bottom: var(--spacing-unit-2);

        > span {
          margin-right: auto;
        }

        > .material-icons {
          margin-left: 4px;
          font-size: 18px;
          width: 18px;
        }
      }

      &:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        font-style: italic;
        font-size: 0.75rem;
      }
    }
  }
`;

export const ListItem: React.FC<Props> = ({ list, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(ROUTES.LIST(String(list.id)));
  }, [list, history]);

  const complete = list.tasks.length !== 0 && !list.tasks.find(t => !t.done);
  const archived = list.archived;

  return (
    <div onClick={handleClick} className={styles} {...props}>
      <div>
        <div>
          <span>{list.name || t('unnamedList')}</span>
          {archived && <Icon icon="archive" />}
          {complete && <Icon icon="check" />}
        </div>
        <div>
          <span>
            {list.tasks.filter(task => task.done).length} / {list.tasks.length}
          </span>
          <span>{list.creationDate.toLocaleDateString()}</span>
        </div>
      </div>
    </div>
  );
};
