import { Dialog } from 'components/Dialog/Dialog';
import { DialogActions } from 'components/Dialog/DialogActions';
import { DialogContent } from 'components/Dialog/DialogContent';
import { css } from 'emotion';
import { useCycle } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { ShareButton } from './ShareButton';

interface Props {
  confirmationTitle: string;
  linkCallback: () => string;
  onClose: () => void;
}

const styles = css`
  overflow-wrap: break-spaces;
  word-wrap: break-spaces;
  word-break: break-all;
  hyphens: none;
`;

export const ShareButtonWithConfirmation: React.FC<Props> = ({ confirmationTitle, linkCallback, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useCycle(false, true);
  const [content, setContent] = useState('');

  const handleShare = useCallback(() => {
    setContent(linkCallback());
    setOpen();
  }, [linkCallback, setOpen]);

  const handleClose = useCallback(() => {
    setOpen();
    onClose();
  }, [onClose, setOpen]);

  return (
    <>
      <ShareButton onClick={handleShare} />
      <Dialog id="share-confirm-dialog" open={open} title={confirmationTitle} onClose={onClose}>
        <DialogContent className={styles}>{content}</DialogContent>
        <DialogActions>
          <Button label={t('ok')} onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};
