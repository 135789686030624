import { css } from 'emotion';
import React from 'react';

export const styles = css`
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--text-color);
  text-align: center;
  padding: 0.5em 0.25em;
  word-spacing: 5px;
  user-select: none;
`;

export const CalendarMonth: React.FC = ({ children }) => <div className={styles}>{children}</div>;
