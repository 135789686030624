import { css, cx } from 'emotion';
import React, { useCallback } from 'react';

interface ColorCircleProps {
  color: string;
  onClick?: (color: string) => void;
  selected?: boolean;
}

const styles = css`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  transition: transform ease-in 0.1s;
  box-shadow: var(--box-shadow);

  :hover {
    cursor: pointer;
    transform: scale(1.5);
  }

  &.selected {
    transform: scale(1.5);
  }
`;

export const ColorCircle: React.FC<ColorCircleProps> = ({ color, onClick, selected }) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(color);
    }
  }, [onClick, color]);

  return (
    <div className={cx(styles, selected && 'selected')} style={{ backgroundColor: color }} onClick={handleClick} />
  );
};
