import { css, cx } from 'emotion';
import React, { ChangeEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { InputContainer } from './InputContainer';

interface Props {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  label: string;
  maxRows?: number;
  minRows?: number;
  name: string;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: string;
}

const styles = css`
  display: block;
  resize: none;
`;

const errorStyles = (rows: number, lineHeight: number) => css`
  > .error {
    top: ${rows * lineHeight + 27}px;
  }
`;

export const Textarea = forwardRef(
  (
    {
      className,
      defaultValue,
      disabled,
      error,
      label,
      maxRows = 10,
      minRows = 4,
      onBlur,
      onChange,
      placeholder,
      readOnly,
      required,
      value,
      ...props
    }: Props,
    parentRef,
  ) => {
    const internalRef = useRef<HTMLTextAreaElement>(null);
    const [rows, setRows] = useState(minRows);
    const [lineHeight, setLineHeight] = useState(22.4);

    let ref = parentRef as React.RefObject<HTMLTextAreaElement>;
    if (!ref) {
      ref = internalRef;
    }

    const adapt = useCallback(
      e => {
        const previousRows = e.target.rows;
        e.target.rows = minRows;

        // tslint:disable-next-line:no-bitwise
        const currentRows = ~~(e.target.scrollHeight / lineHeight) + 1;

        if (currentRows === previousRows) {
          e.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
          e.target.rows = maxRows;
          e.target.scrollTop = e.target.scrollHeight;
        }

        setRows(currentRows < maxRows ? currentRows : maxRows);
      },
      [minRows, maxRows, lineHeight],
    );

    const handleChange = useCallback(
      e => {
        adapt(e);
        if (typeof onChange === 'function') {
          onChange(e);
        }
      },
      [adapt, onChange],
    );

    useEffect(() => {
      setLineHeight(parseFloat(window.getComputedStyle(ref.current!).getPropertyValue('line-height')));
      // tslint:disable-next-line:no-bitwise
      const calculatedRows = ~~(ref.current!.scrollHeight / lineHeight) + 1;
      setRows(calculatedRows > maxRows ? maxRows : calculatedRows < minRows ? minRows : calculatedRows);
    }, [ref, lineHeight, minRows, maxRows]);

    return (
      <InputContainer
        disabled={disabled}
        error={error}
        label={label}
        required={required}
        readOnly={readOnly}
        className={cx(className, errorStyles(rows, lineHeight))}
        ref={ref}
      >
        <textarea
          ref={ref}
          value={value}
          rows={rows}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          className={styles}
          {...props}
        />
      </InputContainer>
    );
  },
);

Textarea.displayName = 'Textarea';
