import { ROUTES } from 'constant';
import * as queryString from 'query-string';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { List } from 'types/List';
import { decodeList, mergeLists } from 'utils';
import { addList, getList, updateList } from 'database/List';

export const SharedList: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { share } = queryString.parse(location.search);

  useEffect(() => {
    const redirect = (result: List | undefined) => {
      if (result) {
        history.push(ROUTES.LIST(result.id));
      }
    };
    if (share) {
      const list = decodeList(share as string);
      getList(list.id).then(existingList => {
        if (existingList) {
          const merge = mergeLists(existingList, list);
          updateList(merge.id, { ...merge }).then(redirect);
        } else {
          addList(list).then(redirect);
        }
      });
    }
  }, [share, history]);

  return null;
};
