import { Button } from 'components/Button/Button';
import { Dialog } from 'components/Dialog/Dialog';
import { DialogActions } from 'components/Dialog/DialogActions';
import { DialogContent } from 'components/Dialog/DialogContent';
import { ListNotFoundError } from 'errors';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface State {
  error?: Error;
  showError: boolean;
}

class BaseErrorBoundary extends React.Component<WithTranslation, State> {
  state: State = {
    error: undefined,
    showError: false,
  };

  dismiss = () => {
    this.setState({ error: undefined, showError: false });
  };

  static getDerivedStateFromError(error: Error) {
    return { showError: true, error };
  }

  render() {
    const { showError, error } = this.state;
    const { t } = this.props;

    let message: string;

    if (error) {
      switch (error.name) {
        case 'ListNotFoundError':
          message = t('listNotFound', { id: (error as ListNotFoundError).id });
          break;
        default:
          message = error.message;
          break;
      }
    } else {
      message = '';
    }

    return (
      <>
        <Dialog id="error-dialog" open={showError} title={t('errorDialogTitle')}>
          <DialogContent>{t(message)}</DialogContent>
          <DialogActions>
            <Button label="ok" onClick={this.dismiss} />
          </DialogActions>
        </Dialog>
        {this.props.children}
      </>
    );
  }
}

export const ErrorBoundary = withTranslation()(BaseErrorBoundary);
