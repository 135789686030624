import { uid } from 'uid';
import { List } from 'types/List';
import { getRandomColor } from 'utils';
import { db } from './index';

const lists = db.lists;

export const getLists = (): Promise<List[]> => lists.orderBy('creationDate').toArray();

export const getList = (id: string): Promise<List | undefined> => lists.get(id);

export const addList = (list: List): Promise<List | undefined> => {
  return lists.add(list).then(id => lists.get(id));
};

export const createList = (name?: string, color?: string): Promise<List | undefined> => {
  const newList: List = {
    id: uid(24),
    name: name || '',
    creationDate: new Date(),
    color: color || getRandomColor(),
    tasks: [],
    archived: false,
  };
  return lists.add(newList).then(id => lists.get(id));
};

export const updateList = (id: string, changes: { [name: string]: unknown }): Promise<List | undefined> => {
  return lists.update(id, changes).then((updatedRecords: number) => {
    if (updatedRecords) {
      return lists.get(id);
    }
    return Promise.resolve(undefined);
  });
};

export const deleteLists = (ids: string[]): Promise<undefined> => {
  return Promise.all(ids.map(id => lists.delete(id))).then();
};
