import { AppLoader } from 'components';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { APP_STATE, LOADING_SCREEN_DURATION, useApp } from 'stores';
import useDarkMode from 'use-dark-mode';
import { Root } from './Root';

export const App: React.FC = () => {
  useDarkMode();
  const { appState } = useApp();

  return (
    <AnimatePresence exitBeforeEnter>
      {appState === APP_STATE.LOADING && <AppLoader key="app-loader" duration={LOADING_SCREEN_DURATION} />}
      {appState === APP_STATE.READY && <Root key="root" />}
    </AnimatePresence>
  );
};
