export default {
  add: 'Add',
  update: 'Update',
  subject: 'Subject',
  done: 'Done',
  todo: 'To do',
  cancel: 'Cancel',
  allLists: 'All the lists',
  listName: 'Name of the list',
  nameList: 'Name the list',
  newList: 'New list',
  newTask: 'New task',
  updateTask: 'Update task',
  unnamedList: 'Anonymous list',
  description: 'Description',
  deadline: 'Deadline',
  clear: 'Clear',
  emptyList: 'No tasks to show',
  emptyLists: "You don't have anything to do at the moment",
  listCount: '{{ count }} list',
  listCount_plural: '{{ count }} lists',
  createdOn: 'Created on {{ date }}',
  errorDialogTitle: 'Oops ! An error has occured',
  listNotFound: 'The list {{ id }} could not be loaded',
  delete: 'Delete',
  deleteListConfirmationTitle: 'Confirm delete',
  deleteListConfirmationContent: 'Do you want to delete this list ?',
  deleteListConfirmationContent_plural: 'Do you want to delete these {{ count }} lists ?',
  archiveListConfirmationTitle: 'Confirm',
  archiveListConfirmationContent: 'Do you want to archive this list ?',
  archiveListConfirmationContent_plural: 'Do you want to archive these {{ count }} lists ?',
  selectedList: '{{ count }} list selected',
  selectedList_plural: '{{ count }} lists selected',
  select: 'Select',
  unselect: 'Unselect',
  archive: 'Archive',
  Unarchive: 'Unarchive',
  shareLink: 'Your share link',
  showArchive: 'Show archive',
  share: 'Share',
};
