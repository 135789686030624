import React from 'react';
import { useTranslation } from 'react-i18next';
import { WEEK_DAYS } from 'utils';
import { CalendarDay } from './CalendarDay';

interface Props {
  day: string;
  index: number;
}

export const DayLabel: React.FC<Props> = ({ day, index }) => {
  const { t } = useTranslation();
  const dayLabel = t(WEEK_DAYS[day]).toUpperCase();

  return <CalendarDay index={index}>{dayLabel}</CalendarDay>;
};
