import { css, cx } from 'emotion';
import React from 'react';

interface DialogActions {
  className?: string;
}

const styles = css`
  display: flex;
  align-items: center;
  padding: var(--spacing-unit);
  justify-content: flex-end;
  flex: 0 0 auto;

  > * + * {
    margin-left: var(--spacing-unit);
  }
`;

export const DialogActions: React.FC<DialogActions> = ({ className, ...props }) => {
  return <div className={cx(styles, className)} {...props} />;
};
