import { css } from 'emotion';
import { motion, MotionValue, Transition, useTransform, Variants } from 'framer-motion';
import React, { useMemo } from 'react';
import useDarkMode from 'use-dark-mode';

interface Props {
  open: boolean;
  toggle: () => void;
  yMotion: MotionValue<number>;
}

const Path: React.FC<{
  stroke?: string | MotionValue<string>;
  variants?: Variants;
  d?: string;
  transition?: Transition;
}> = props => (
  <motion.path fill="transparent" strokeWidth="3" stroke="var(--text-color)" strokeLinecap="round" {...props} />
);

const styles = css`
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: transparent;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  > svg {
    margin-top: 2px;
  }
`;

const toggleBackgroundStyles = css`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 56px;
  background-color: var(--primary);
  opacity: 0;
  box-shadow: var(--box-shadow);
`;

const backgroundOpacityRange = [0, 140];
const colorRange = [0, 80];
const backgroundOpacityOutput = [0, 1];

export const MenuToggle: React.FC<Props> = ({ open, toggle, yMotion }) => {
  const darkMode = useDarkMode().value;
  const textColor = useMemo(
    () => {
      return window.getComputedStyle(document.body).getPropertyValue('--text-color').trim();
    },
    // eslint-disable-next-line
    [darkMode]
  );

  const backgroundOpacity = useTransform(yMotion, backgroundOpacityRange, backgroundOpacityOutput);
  const colorValue = useTransform(yMotion, colorRange, ['#ffffff', textColor]);
  const stroke = open ? textColor : colorValue;

  return (
    <>
      <motion.div className={toggleBackgroundStyles} style={{ opacity: backgroundOpacity }} />
      <motion.button initial={false} onClick={toggle} animate={open ? 'open' : 'closed'} className={styles}>
        <svg width="22" height="22" viewBox="0 0 22 22">
          <Path
            stroke={stroke}
            variants={{
              closed: { d: 'M 2 2.5 L 20 2.5' },
              open: { d: 'M 3 16.5 L 17 2.5' },
            }}
          />
          <Path
            stroke={stroke}
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            stroke={stroke}
            variants={{
              closed: { d: 'M 2 16.346 L 20 16.346' },
              open: { d: 'M 3 2.5 L 17 16.346' },
            }}
          />
        </svg>
      </motion.button>
    </>
  );
};
