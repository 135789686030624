import { css, cx } from 'emotion';
import { motion } from 'framer-motion';
import React from 'react';

export const styles = css`
  border-radius: 4px;
  background-color: var(--secondary);
  overflow: hidden;
  max-width: 400px;
`;

const transition = { duration: 0.2 };

const variants = {
  enter: {
    opacity: 1,
    transition,
  },
  exit: {
    opacity: 0,
    transition,
  },
};

const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation();

export const CalendarContainer: React.FC<{ className?: string; id?: string }> = ({ children, className, id }) => {
  return (
    <motion.div
      onClick={stopPropagation}
      exit="exit"
      animate="enter"
      initial="exit"
      className={cx(styles, className)}
      id={id}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};
