export const breakpoints = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1920.1,
};

export const _breakpoints = {
  small: breakpoints.sm,
  medium: breakpoints.md,
  large: breakpoints.lg,
  xlarge: breakpoints.xl,
};
