import { IconButton } from '@rmwc/icon-button';
import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Controls/Checkbox';
import { Dialog } from 'components/Dialog/Dialog';
import { DialogActions } from 'components/Dialog/DialogActions';
import { DialogContent } from 'components/Dialog/DialogContent';
import { DateInput } from 'components/Input/DateInput';
import { Input } from 'components/Input/Input';
import { Textarea } from 'components/Input/Textarea';
import { breakpoints } from 'constant';
import { css } from 'emotion';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Task } from 'types/Task';
import { uid } from 'uid';

interface Props {
  open: boolean;
  onCancel: () => void;
  task?: Task;
  onSubmit: (task: Task) => void;
  onDelete: () => void;
}

const formStyles = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  > fieldset {
    border: none;
    margin: 0;
    width: 100%;
    padding: 0 0 var(--spacing-unit-3);

    @media (min-width: ${breakpoints.md}px) {
      &:first-child {
        width: 66%;
      }

      &:nth-child(2) {
        width: 30%;
      }
    }
  }
`;

const actions = css`
  justify-content: space-between;

  > span.main-actions {
    margin-left: auto;

    > * + * {
      margin-left: var(--spacing-unit);
    }
  }
`;

export const TaskDialog: React.FC<Props> = ({ open, onCancel, onSubmit: onTaskSubmit, onDelete, task }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: task || {
      deadline: undefined,
      description: '',
      subject: '',
      done: false,
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const newTask: Partial<Task> = { ...values };

      if (!task) {
        newTask.id = uid(24);
        newTask.creationDate = new Date();
      }

      onTaskSubmit(newTask as Task);
      resetForm();
    },
  });

  const cancel = useCallback(() => {
    onCancel();
    formik.resetForm();
  }, [onCancel, formik]);

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue('done', e.currentTarget.checked);
    },
    [formik],
  );

  return (
    <Dialog id="task-dialog" open={open} title={t(task ? 'updateTask' : 'newTask')} onClose={cancel}>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="new-list-form" autoComplete="off" className={formStyles}>
          <fieldset>
            <Input
              autoFocus
              name="subject"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.subject}
              label={t('subject')}
              required
            />
          </fieldset>
          <fieldset>
            <DateInput
              label={t('deadline')}
              name="deadline"
              onChange={formik.handleChange}
              value={formik.values.deadline}
            />
          </fieldset>
          <fieldset>
            <Textarea
              label={t('description')}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </fieldset>

          <Checkbox label={t('done')} name="done" checked={formik.values.done} onChange={handleCheckboxChange} />
        </form>
      </DialogContent>
      <DialogActions className={actions}>
        {task && task.id !== undefined && (
          <span>
            <IconButton icon="delete" onClick={onDelete} />
          </span>
        )}
        <span className="main-actions">
          <Button onClick={cancel}>{t('cancel')}</Button>
          <Button type="submit" outlined form="new-list-form" disabled={formik.values.subject === ''}>
            {t(task ? 'update' : 'add')}
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  );
};
