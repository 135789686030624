import { css } from 'emotion';
import React from 'react';

const styles = css`
  display: grid;
  justify-items: center;
  grid-template: repeat(7, auto) / repeat(7, auto);
`;

export const CalendarGrid: React.FC = ({ children }) => <div className={styles}>{children}</div>;
