import { css, cx } from 'emotion';
import React, { useCallback } from 'react';
import { getMonthFirstDate, isSameDay, isSameMonth } from 'utils';
import { CalendarCell } from './CalendarCell';

interface Props {
  date: Date;
  current?: Date;
  index: number;
  onClick: (date: Date) => void | undefined;
  calendarMonth: { month: number; year: number };
}

const differentMonthStyles = css`
  &:not(.selected) > span {
    opacity: 0.4;
  }
`;

export const DayNumber: React.FC<Props> = ({ children, date, calendarMonth, current, index, onClick }) => {
  const sameMonth = isSameMonth(date, getMonthFirstDate(calendarMonth.month, calendarMonth.year));
  const sameDay = current !== undefined && isSameDay(date, current);
  const today = isSameDay(date);

  const handleClick = useCallback((): void => {
    onClick(date);
  }, [onClick, date]);

  return (
    <CalendarCell
      index={index}
      onClick={handleClick}
      className={cx(
        sameMonth ? undefined : differentMonthStyles,
        sameDay ? 'selected' : undefined,
        today ? 'today' : undefined,
      )}
    >
      {children}
    </CalendarCell>
  );
};
