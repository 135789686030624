import { IconButton } from '@rmwc/icon-button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CALENDAR_MONTHS } from 'utils';
import { CalendarHeader } from './CalendarHeader';
import { CalendarMonth } from './CalendarMonth';

interface Props {
  month: number;
  year: number;
  onPrevious: () => void;
  onNext: () => void;
}

export const MonthAndYear: React.FC<Props> = ({ month, year, onPrevious, onNext }) => {
  const { t } = useTranslation();
  const monthName = t(Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))]);

  return (
    <CalendarHeader>
      <IconButton icon="keyboard_arrow_left" onClick={onPrevious} />
      <CalendarMonth>
        {monthName} {year}
      </CalendarMonth>
      <IconButton icon="keyboard_arrow_right" onClick={onNext} />
    </CalendarHeader>
  );
};
