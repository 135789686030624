import { Dialog } from 'components/Dialog/Dialog';
import { DialogActions } from 'components/Dialog/DialogActions';
import { DialogContent } from 'components/Dialog/DialogContent';
import { useCycle } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { DeleteButton } from './DeleteButton';

interface Props {
  callback: () => void | undefined;
  confirmationTitle: string;
  confirmationContent: string;
}

export const DeleteButtonWithConfirmation: React.FC<Props> = ({ callback, confirmationTitle, confirmationContent }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useCycle(false, true);

  return (
    <>
      <DeleteButton onClick={setOpen} />
      <Dialog id="delete-confirm-dialog" open={open} title={confirmationTitle}>
        <DialogContent>{confirmationContent}</DialogContent>
        <DialogActions>
          <Button label={t('cancel')} onClick={setOpen} />
          <Button label={t('delete')} onClick={callback} />
        </DialogActions>
      </Dialog>
    </>
  );
};
