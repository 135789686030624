import { Portal } from '@rmwc/base';
import {
  Fade,
  Header,
  MenuToggle,
  MobileSidebar,
  MotionRedirect,
  Router,
  ScrollView,
  SharedList,
  Sidebar,
} from 'components';
import { ROUTES } from 'constant';
import { css, cx } from 'emotion';
import { AnimatePresence, useCycle, useElementScroll } from 'framer-motion';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { HideAt, ShowAt } from 'react-with-breakpoints';
import { ListsModule } from './Lists';
import { ListsHeader } from './Lists/All/ListsHeader';
import { ListHeader } from './Lists/List/ListHeader';

const styles = css`
  display: flex;
  background-color: var(--primary);
  color: var(--text-color);
  > section {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
  },
`;

export const Root: React.FC = () => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const location = useLocation();
  const { scrollY } = useElementScroll(scrollRef);

  return (
    <Fade className={cx('motion-container', styles)}>
      <SharedList />
      <HideAt breakpoint="largeAndAbove">
        <MenuToggle open={isOpen} toggle={toggleOpen} yMotion={scrollY} />
        <MobileSidebar open={isOpen} handler={toggleOpen} />
      </HideAt>
      <ShowAt breakpoint="largeAndAbove">
        <Sidebar />
      </ShowAt>
      <section>
        <ScrollView scrollableNodeProps={{ ref: scrollRef }}>
          <Header>
            <AnimatePresence exitBeforeEnter>
              <Switch key={location.pathname} location={location}>
                <MotionRedirect exact from="/" to={ROUTES.LISTS()} />
                <Route path={ROUTES.LIST()}>
                  <ListHeader yMotion={scrollY} />
                </Route>
                <Route path={ROUTES.LISTS()} component={ListsHeader} />
                <MotionRedirect to={ROUTES.LISTS()} />
              </Switch>
            </AnimatePresence>
          </Header>
          <main id="main">
            <Portal />
            <Router>
              <AnimatePresence exitBeforeEnter>
                <Switch key={location.pathname} location={location}>
                  <MotionRedirect exact from="/" to={ROUTES.LISTS()} />
                  <Route path={ROUTES.LISTS()} component={ListsModule} />
                  <MotionRedirect to={ROUTES.LISTS()} />
                </Switch>
              </AnimatePresence>
            </Router>
          </main>
        </ScrollView>
      </section>
    </Fade>
  );
};
