import React from 'react';
import ReactDOM from 'react-dom';

// eslint-disable-next-line
export const withPortal = (targetId: string) => (Component: React.ReactNode) => {
  // eslint-disable-next-line
  return class Portal extends React.Component<any> {
    private target: HTMLElement | null;
    private readonly wrapper: HTMLDivElement;

    constructor(props: Readonly<Record<string, unknown>>) {
      super(props);

      const target = document.getElementById(targetId);

      if (target === null) {
        throw new Error(`No element with id ${targetId} found`);
      }

      this.target = target;
      this.wrapper = document.createElement('div');
      target.appendChild(this.wrapper);
    }

    componentWillUnmount() {
      this.target!.removeChild(this.wrapper);
    }

    render() {
      // @ts-ignore
      return ReactDOM.createPortal(<Component {...this.props} />, this.wrapper);
    }
  };
};
