import { AddButton, Divider, Fade, TaskDialog } from 'components';
import { ROUTES } from 'constant';
import { css } from 'emotion';
import { ListNotFoundError } from 'errors';
import { motion, useCycle } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { List as ListType } from 'types/List';
import { Task } from 'types/Task';
import { getList, updateList } from 'database/List';
import { useDatabaseChange } from 'hooks/use-database-change';
import { includesOneOf } from 'utils';
import { TaskItem } from './TaskItem';

const styles = css`
  padding: var(--spacing-unit);

  .subheader {
    display: flex;
    padding: var(--spacing-unit-2) var(--spacing-unit);

    > .creation-date {
      margin-left: auto;
    }
  }

  ul {
    margin-bottom: 80px;
  }
`;

export const List: React.FC = () => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const { id }: { id: string } = useParams();
  const [open, setOpen] = useCycle(false, true);
  const [list, setList] = useState<ListType>();
  const [selectedTask, setSelectedTask] = useState<Task>();

  useEffect(() => {
    return () => {
      document.getElementById('root')?.style.removeProperty('--accent');
    };
  }, []);

  const load = useCallback((): void => {
    getList(id).then(result => {
      setList(() => {
        if (result === undefined) {
          replace(ROUTES.LISTS());
          throw new ListNotFoundError(id);
        } else {
          document.getElementById('root')?.style.setProperty('--accent', `var(${result.color})`);
          result.tasks = result.tasks.sort((a, b) => a.creationDate.getTime() - b.creationDate.getTime());
          return result;
        }
      });
    });
  }, [id, replace]);

  const shouldReload = useCallback(
    (changes: any[]): boolean => {
      const item = changes.find(change => change.key === id);
      return item && includesOneOf(Object.keys(item.mods), ['tasks', 'color', 'archived']);
    },
    [id],
  );

  useDatabaseChange(load, shouldReload);

  const updateTask = useCallback(
    (task: Task) => {
      if (list) {
        Object.assign(
          list.tasks.find(el => el.id === task.id),
          task,
        );
        updateList(list.id, { tasks: list.tasks });
        setOpen(0);
        setSelectedTask(undefined);
      }
    },
    [list, setOpen],
  );

  const addTask = useCallback(
    (task: Task) => {
      if (list) {
        updateList(list.id, { tasks: list.tasks.concat(task) });
        setOpen(0);
      }
    },
    [list, setOpen],
  );

  const handleTaskStatus = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (list) {
        const { name, checked } = e.currentTarget;
        const newTasks = list.tasks.map(task => (task.id === name ? { ...task, done: checked } : task))!;
        setList(oldList => {
          oldList!.tasks = newTasks;
          return oldList;
        });
        updateList(list.id, { tasks: newTasks });
      }
    },
    [list],
  );

  const select = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (list) {
        const selectedId = e.currentTarget.getAttribute('data-id')!;
        setSelectedTask(list.tasks.find(el => el.id === selectedId));
        setOpen(1);
      }
    },
    [list, setOpen],
  );

  const unselect = useCallback(() => {
    setSelectedTask(undefined);
    setOpen(1);
  }, [setOpen]);

  const handleDelete = useCallback(() => {
    setOpen();
    updateList(list!.id, { tasks: list!.tasks.filter(e => e.id !== selectedTask!.id) }).then(() =>
      setSelectedTask(undefined),
    );
  }, [list, selectedTask, setSelectedTask, setOpen]);

  if (list === undefined) {
    return null;
  }

  return (
    <Fade className={styles}>
      <TaskDialog
        open={open}
        onCancel={setOpen}
        onSubmit={selectedTask ? updateTask : addTask}
        task={selectedTask}
        onDelete={handleDelete}
      />
      <AddButton onClick={unselect} />
      <div className="subheader">
        {list.tasks.length === 0 ? (
          <span className="count">{t('emptyList')}</span>
        ) : (
          <span className="count">
            {list.tasks.filter(task => task.done).length} / {list.tasks.length}
          </span>
        )}

        <span className="creation-date">{t('createdOn', { date: list.creationDate.toLocaleDateString() })}</span>
      </div>
      <Divider />
      <motion.ul>
        {list.tasks
          .sort((a, b) => {
            if ((a.done && b.done) || (!a.done && !b.done)) {
              return a.creationDate.getTime() - b.creationDate.getTime();
            } else {
              return a.done ? 1 : -1;
            }
          })
          .map(task => (
            <TaskItem task={task} onChange={handleTaskStatus} onSelect={select} key={task.id} />
          ))}
      </motion.ul>
    </Fade>
  );
};
