import { motion } from 'framer-motion';
import React from 'react';

const variants = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

export const Fade: React.FC<{ className?: string }> = props => {
  return <motion.div initial="exit" animate="enter" exit="exit" variants={variants} {...props} />;
};
