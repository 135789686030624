import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.15,
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      duration: 0.15,
      y: { stiffness: 1000 },
    },
  },
};

export const NavItem: React.FC<Props> = ({ ...props }) => {
  return <motion.li exit={{ opacity: 0, scale: 0.8 }} variants={variants} {...props} layout="position" />;
};
