import React, { useCallback, useState } from 'react';

type f = (e: React.TouchEvent<HTMLElement>) => void | undefined;

const touchEnabled = (() => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
})();

// eslint-disable-next-line
export const useLongPress = (onLongPress: f, timeout = 500): any => {
  const [, setRef] = useState<NodeJS.Timeout>();

  const onTouchStart = useCallback(
    (e: React.TouchEvent<HTMLElement>) => {
      if (touchEnabled) {
        setRef(setTimeout(() => onLongPress(e), timeout));
      }
    },
    [onLongPress, timeout],
  );

  const onTouchEnd = useCallback(() => {
    if (touchEnabled) {
      setRef(oldRef => {
        if (oldRef) {
          clearTimeout(oldRef);
        }
        return undefined;
      });
    }
  }, []);

  const onTouchCancel = onTouchEnd;

  const onTouchMove = onTouchEnd;

  const onContextMenu = useCallback((e: React.TouchEvent<HTMLElement>) => {
    e.preventDefault();
    return false;
  }, []);

  return { onTouchEnd, onTouchStart, onTouchMove, onTouchCancel, onContextMenu };
};
