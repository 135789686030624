import { motion } from 'framer-motion';
import React from 'react';

const variants = {
  initial: { opacity: 0, scale: 0.5 },
  enter: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
  exit: { opacity: 0, scale: 0.5, transition: { duration: 0.3 } },
};

export const Scale: React.FC<{ className?: string }> = (props: unknown) => {
  return <motion.div initial="initial" animate="enter" exit="exit" variants={variants} {...props} />;
};
