import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { calendar, getNextMonth, getPreviousMonth, WEEK_DAYS } from 'utils';
import { Button } from '../../Button/Button';
import { CalendarActions } from './CalendarActions';
import { CalendarContainer } from './CalendarContainer';
import { CalendarGrid } from './CalendarGrid';
import { CalendarHeader } from './CalendarHeader';
import { DayLabel } from './DayLabels';
import { DayNumber } from './DayNumber';
import { MonthAndYear } from './MonthAndYear';

interface State {
  current?: Date;
  month: number;
  year: number;
}

interface Props {
  date?: Date;
  initialDate?: Date;
  onSelect: (date?: Date) => void | undefined;
  className?: string;
  id?: string;
}

export const Calendar: React.FC<Props> = ({
  className,
  date: selectedDate,
  initialDate = new Date(),
  onSelect,
  id,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(() => {
    const base = selectedDate || initialDate;
    return {
      current: selectedDate,
      month: base?.getMonth() + 1,
      year: base?.getFullYear(),
    };
  });

  const calendarDates = useMemo(() => calendar(state.month, state.year), [state.month, state.year]);

  const prev = useCallback(() => {
    setState(oldState => ({ ...oldState, ...getPreviousMonth(oldState.month, oldState.year) }));
  }, []);

  const next = useCallback(() => {
    setState(oldState => ({ ...oldState, ...getNextMonth(oldState.month, oldState.year) }));
  }, []);

  const select = useCallback((date: Date) => {
    setState(oldState => ({ ...oldState, current: date }));
  }, []);

  const validate = useCallback(() => {
    onSelect(state.current);
  }, [onSelect, state]);

  const reset = useCallback(() => {
    onSelect(undefined);
    setState(oldState => ({ ...oldState, current: undefined }));
  }, [onSelect]);

  return (
    <CalendarContainer className={className} id={id}>
      <CalendarHeader>
        <MonthAndYear month={state.month} year={state.year} onNext={next} onPrevious={prev} />
      </CalendarHeader>
      <CalendarGrid>
        {Object.keys(WEEK_DAYS).map((day, index) => (
          <DayLabel key={`${day}-${index}`} day={day} index={index} />
        ))}

        {calendarDates.map((dateArray, index) => {
          const date = new Date(dateArray.join('-'));
          return (
            <DayNumber
              key={date.getTime()}
              onClick={select}
              calendarMonth={state}
              index={index}
              date={date}
              current={state.current}
            >
              {dateArray[2]}
            </DayNumber>
          );
        })}
      </CalendarGrid>
      <CalendarActions>
        <Button onClick={reset} label={t('clear')} />
        <Button onClick={validate} label={t('Ok')} disabled={state.current === undefined} outlined />
      </CalendarActions>
    </CalendarContainer>
  );
};
