import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import { Checkbox as RMWCCheckbox, CheckboxProps } from '@rmwc/checkbox';
import { css, cx, keyframes } from 'emotion';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  name: string;
}

const keyframesOut = keyframes`
  0%, 80% {
    border-color: var(--accent);
    background-color: var(--accent);
  }
  
  100% {
    border-color: var(--text-color);
    background-color: transparent;
  }
`;

const keyframesIn = keyframes`
  0% {
    border-color: var(--text-color);
    background-color: transparent;
  }
  
  50% {
    border-color: var(--accent);
    background-color: var(--accent);
  }
`;

const styles = css`
  font-family: inherit;
  color: var(--text-color);
  font-size: 0.9rem;
  line-height: 1.15rem;

  &.mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background::before,
  &.mdc-checkbox .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background::before {
    background-color: var(--accent);
  }
  &.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before,
  &.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
    background-color: var(--accent);
  }

  &.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
  &.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after,
  > .mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
  > .mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
    background-color: var(--accent);
  }

  &.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
  &.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after,
  > .mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
  > .mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
    background-color: var(--accent);
  }

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
    border-color: var(--accent);
    background-color: var(--accent);
  }

  &.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
  &.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
  > .mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
  > .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
    animation-name: ${keyframesIn};
  }

  &.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
  &.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
  > .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
  > .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
    animation-name: ${keyframesOut};
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: var(--text-color);
  }

  > label {
    color: var(--text-color);
  }
`;

export const Checkbox: React.FC<CheckboxProps & Props> = ({
  className,
  checked: initialChecked = false,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = useState<boolean>(!!initialChecked);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleChange = useCallback(
    e => {
      setChecked(old => !old);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (initialChecked !== checked) {
      timeoutRef.current = setTimeout(() => setChecked(old => !old), 200);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef, checked, initialChecked]);

  return <RMWCCheckbox checked={checked} onChange={handleChange} className={cx(styles, className)} {...props} />;
};
