import { Icon } from '@rmwc/icon';
import { ListCreationDialog } from 'components/ListCreationDialog/ListCreationDialog';
import { ROUTES } from 'constant';
import { css } from 'emotion';
import { AnimatePresence, motion, useCycle, Variants } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { List } from 'types/List';
import { createList, getLists } from 'database/List';
import { useDatabaseChange } from 'hooks/use-database-change';
import { Item } from './Item';
import { NavItem } from './NavItem';

interface Props {
  handler?: () => void | undefined;
}

const ITEM_HEIGHT = 46;
const BASE_INDICATOR = { offset: 0, color: 'transparent' };

const indicatorVariants: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.15,
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      duration: 0.15,
      y: { stiffness: 1000 },
    },
  },
};

const variants: Variants = {
  open: {
    width: 300,
    transition: {
      staggerChildren: 0.03,
      delayChildren: 0.1,
      width: {
        duration: 0,
      },
    },
  },
  closed: {
    width: 0,
    transition: {
      staggerChildren: 0.015,
      staggerDirection: -1,
      width: {
        duration: 0,
        delay: 1,
      },
    },
  },
};

const styles = css`
  ul {
    position: relative;

    > li,
    li > button {
      outline: none;
      font-size: 1.18rem;
      height: ${ITEM_HEIGHT}px;
      color: var(--text-color);
      transition: color ease-in 200ms 100ms;

      > button {
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 0;
        font-weight: inherit;
      }

      > a,
      > button {
        display: flex;
        height: 100%;
        width: 300px;
      }

      > a:focus > span.item,
      > button:focus > span.item {
        background-color: rgba(0, 0, 0, 0.2);
      }

      span.item {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 var(--spacing-unit-2);
        white-space: nowrap;
        width: 100%;
        cursor: pointer;
        transition: background-color 150ms ease;

        :hover {
          background-color: rgba(0, 0, 0, 0.2);
        }

        > .material-icons {
          margin-right: var(--spacing-unit-2);
        }

        > label {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    > .indicator {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      transform: translateY(-${ITEM_HEIGHT}px);
      transition: background-color ease-in 200ms, transform ease-in 200ms;
    }
  }
`;

export const Nav: React.FC<Props> = ({ handler }) => {
  const { t } = useTranslation();
  const [lists, setLists] = useState<List[]>([]);
  const history = useHistory();
  const nav = useRef<HTMLUListElement>(null);
  const [indicator, setIndicator] = useState(BASE_INDICATOR);
  const [open, setOpen] = useCycle(false, true);

  const load = useCallback(() => {
    getLists().then(r => setLists(r.filter(l => !l.archived)));
  }, []);

  useDatabaseChange(load);

  useEffect(() => {
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      let hasActiveItem = false;
      mutations.forEach(mutation => {
        const target = mutation.target as HTMLLIElement;
        if (target.getAttribute('active') === String(true)) {
          hasActiveItem = true;
          setIndicator({
            offset: target.offsetTop,
            color: target.getAttribute('data-color')!,
          });
        }
      });

      if (!hasActiveItem) {
        setIndicator(BASE_INDICATOR);
      }
    });

    const activeItem: ChildNode | undefined = Array.from(nav.current!.childNodes).find(
      item => (item as HTMLLIElement).getAttribute('active') === String(true),
    );

    if (activeItem) {
      const item = activeItem as HTMLLIElement;
      setIndicator({ offset: item.offsetTop, color: item.getAttribute('data-color')! });
    }

    observer.observe(nav.current!, { attributes: true, subtree: true, attributeFilter: ['active', 'data-color'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleNewListButtonClick = useCallback(() => setOpen(), [setOpen]);

  const handleNew = useCallback(
    (name?: string, color?: string) => {
      setOpen(0);
      createList(name, color).then(list => {
        if (handler) {
          handler();
        }
        if (list) {
          setLists(prevLists => prevLists.concat(list));
          history.push(ROUTES.LIST(String(list.id)));
        }
      });
    },
    [history, setOpen, handler],
  );

  const indicatorStyles = useMemo(() => {
    return nav.current &&
      Array.from(nav.current.childNodes).find(item => (item as HTMLLIElement).getAttribute('active') === String(true))
      ? css`
          > .indicator {
            background-color: var(${indicator.color});
            transform: translateY(${indicator.offset}px) !important;
          }
        `
      : undefined;
  }, [indicator]);

  return (
    <motion.nav className={styles}>
      <motion.ul ref={nav} className={indicatorStyles} variants={variants}>
        <NavItem key="new">
          <ListCreationDialog onNew={handleNew} onCancel={setOpen} open={open} />
          <button onClick={handleNewListButtonClick} type="button">
            <span className="item">
              <Icon icon="add_rounded" />
              <label>{t('newList')}</label>
            </span>
          </button>
        </NavItem>
        <Item
          color="--accent"
          icon={<Icon icon="list_rounded" />}
          name={t('allLists')}
          to={ROUTES.LISTS()}
          onClick={handler}
        />
        <AnimatePresence>
          {lists.map((list, i) => (
            <Item
              key={`${list.id}`}
              name={list.name}
              color={list.color}
              to={ROUTES.LIST(String(list.id))}
              data-i={i}
              onClick={handler}
            />
          ))}
        </AnimatePresence>
        <motion.li className="indicator" variants={indicatorVariants} />
      </motion.ul>
    </motion.nav>
  );
};
