import { MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu';
import { css } from 'emotion';
import React, { useCallback } from 'react';

interface Props {
  children: React.ReactElement;
  tag: any;
  menu: ({ label: string; action: (arg: any) => void | undefined } | null)[];
  [k: string]: any;
}

const styles = css`
  min-width: 250px;
  background-color: var(--primary);
  color: var(--text-color);
  box-shadow: var(--raised-box-shadow);

  > ul {
    padding: var(--spacing-unit) 0;
    > li {
      cursor: pointer;
      padding: var(--spacing-unit) var(--spacing-unit-2);

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

export const ContextMenu: React.FC<Props> = ({ children, menu, tag, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const onContextMenu = useCallback((e: Event) => {
    e.preventDefault();
    setOpen(true);
    return false;
  }, []);

  return (
    <MenuSurfaceAnchor tag={tag} {...props}>
      <MenuSurface open={open} onClose={close} className={styles} renderToPortal>
        <ul>
          {menu
            .filter(el => el !== null)
            .map((item, index) => {
              const handleClick = (e: any) => {
                item?.action(e);
                close();
              };

              return (
                <li key={index} onClick={handleClick}>
                  {item?.label}
                </li>
              );
            })}
        </ul>
      </MenuSurface>
      {React.cloneElement(children, { ...children.props, onContextMenu })}
    </MenuSurfaceAnchor>
  );
};
