import React, { useEffect, useRef } from 'react';

export const useDimensions = (ref: React.RefObject<HTMLElement>): { width: number; height: number } => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = ref.current!.offsetWidth;
    dimensions.current.height = ref.current!.offsetHeight;
  }, [ref]);

  return dimensions.current;
};
