import React, { useEffect, useState } from 'react';

export const LOADING_SCREEN_DURATION = 1500;

export const APP_STATE = {
  LOADING: 'loading',
  READY: 'ready',
};

type AppContextType = {
  appState: string;
};

export const AppContext = React.createContext<AppContextType>({
  appState: APP_STATE.LOADING,
});

export const AppProvider: React.FC = ({ children }) => {
  const [appState, setAppState] = useState(APP_STATE.LOADING);

  useEffect(() => {
    if (appState === APP_STATE.LOADING) {
      setTimeout(() => {
        Promise.resolve(setAppState(APP_STATE.READY));
      }, LOADING_SCREEN_DURATION);
    }
  }, [appState]);

  return <AppContext.Provider value={{ appState }}>{children}</AppContext.Provider>;
};

export const useApp = (): AppContextType => React.useContext(AppContext);
