import { useCallback, useEffect } from 'react';
import { db } from 'database';

const shouldReloadDefault = () => true;

export const useDatabaseChange = (
  callback: () => void | undefined,
  shouldReload: (changes: unknown[]) => boolean = shouldReloadDefault,
  initial = true,
): void => {
  const callbackOnChanges = useCallback(
    (changes: unknown[]) => {
      if (shouldReload(changes)) {
        callback();
      }
    },
    [shouldReload, callback],
  );

  useEffect(() => {
    if (initial) {
      callback();
    }

    db.on('changes').subscribe(callbackOnChanges);

    return (): void => {
      db.on('changes').unsubscribe(callbackOnChanges);
    };
  }, [callback, initial, callbackOnChanges]);
};
