import animationData from 'assets/lottie-loader.json';
import { css } from 'emotion';
import { motion } from 'framer-motion';
import React from 'react';
import Lottie from 'react-lottie';

interface Props {
  duration: number;
}

const variants = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

const loaderVariants = {
  init: { scale: 0.7 },
  enter: {
    scale: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const progressVariants = {
  init: { width: 0 },
  animate: (duration: number) => ({
    width: '100%',
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: duration / 1000,
    },
  }),
};

const styles = css`
  height: 100%;
  width: 100%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 400px;
  }

  > .progress {
    margin-top: 50px;
    width: 90%;
    max-width: 360px;
    height: 3px;
    background-color: var(--secondary);
    position: relative;

    > .progress-bar {
      position: absolute;
      height: 100%;
      background: linear-gradient(
        to left bottom,
        #b70982,
        #bb2176,
        #ba4165,
        #bf565c,
        #d87349,
        #e79028,
        #e7a205
      );
    }
`;

export const AppLoader: React.FC<Props> = ({ duration }) => {
  const options = {
    loop: false,
    autoplay: true,
    animationData,
  };

  return (
    <motion.div initial="exit" animate="enter" exit="exit" variants={variants} className={styles}>
      <motion.div animate="enter" initial="init" variants={loaderVariants}>
        <Lottie options={options} />
      </motion.div>

      <div className="progress">
        <motion.div
          animate="animate"
          initial="init"
          className="progress-bar"
          variants={progressVariants}
          custom={duration}
        />
      </div>
    </motion.div>
  );
};
