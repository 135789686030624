import '@material/button/dist/mdc.button.css';
import { Button as RMWCButton, ButtonProps } from '@rmwc/button';
import { css, cx } from 'emotion';
import React from 'react';

export interface CustomButtonProps {
  className?: string;
  onClick?: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined) | (() => void);
  type?: string;
  form?: string;
}

const styles = css`
  font-family: inherit;
  color: var(--accent) !important;
  transition: ease-in 200ms;
  transition-property: color, background-color, border-color, opacity;

  &[disabled] {
    color: var(--text-color) !important;
  }

  > .mdc-button__ripple {
    &::before,
    &::after {
      background-color: var(--accent) !important;
    }
  }
`;

const outlinedStyles = css`
  border-color: var(--accent) !important;

  &[disabled] {
    border-color: var(--text-color) !important;
    opacity: 0.6;
  }
`;

const raisedStyles = css`
  background-color: var(--accent) !important;
  color: white !important;

  &[disabled] {
    background-color: var(--text-color) !important;
    color: white !important;
    opacity: 0.6;
  }
`;

export const Button = React.forwardRef((props: ButtonProps & CustomButtonProps, ref: any) => {
  return (
    <RMWCButton
      ref={ref}
      {...props}
      className={cx(
        props.className,
        styles,
        props.outlined && outlinedStyles,
        (props.raised || props.unelevated) && raisedStyles,
      )}
    />
  );
});

Button.displayName = 'Button';
