import { withPortal } from 'components/Portal/withPortal';
import { ScrollView } from 'components/ScrollView/ScrollView';
import { css } from 'emotion';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Backdrop } from './Backdrop';
import { DialogContainer } from './DialogContainer';
import { DialogTitle } from './DialogTitle';

interface DialogProps {
  backdropProps?: { className?: string };
  children: React.ReactNode;
  className?: string;
  id: string;
  onClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined;
  open: boolean;
  title?: string;
}

const styles = css`
  min-height: 100%;
`;

const DialogBase: React.FC<DialogProps> = ({ title, open, onClose, backdropProps, children, ...props }) => {
  return (
    <AnimatePresence>
      {open && (
        <Backdrop onClick={onClose} {...backdropProps}>
          <DialogContainer {...props}>
            <ScrollView className={styles}>
              {title && <DialogTitle>{title}</DialogTitle>}
              {children}
            </ScrollView>
          </DialogContainer>
        </Backdrop>
      )}
    </AnimatePresence>
  );
};

type DialogType = React.FC<DialogProps>;

// @ts-ignore dirty-fix
export const Dialog: DialogType = withPortal('root')(DialogBase);
