import '@material/fab/dist/mdc.fab.css';
import { Fab as RMWCFab, FabProps } from '@rmwc/fab';
import { css, cx } from 'emotion';
import React from 'react';
import { CustomButtonProps } from './Button';

const styles = css`
  background-color: var(--accent) !important;
`;

export const Fab = React.forwardRef((props: FabProps & CustomButtonProps, ref: any) => {
  return <RMWCFab {...props} className={cx(styles, props.className)} ref={ref} />;
});

Fab.displayName = 'Fab';
