import { css } from 'emotion';
import { motion } from 'framer-motion';
import React from 'react';

const styles = css`
  margin: 4px var(--spacing-unit);
  background-color: var(--tertiary);
  border: none;
  height: 1px;
`;

const transition = { transition: { duration: 0.6 } };

const variants = {
  enter: {
    scale: 1,
    ...transition,
  },
  exit: {
    scale: 0,
    ...transition,
  },
};

export const Divider: React.FC = () => {
  return <motion.hr className={styles} variants={variants} />;
};
