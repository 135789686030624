import { css } from 'emotion';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { NavItem } from './NavItem';

interface Props {
  color: string;
  icon?: React.ReactNode;
  name: React.ReactNode;
  to: string;
  onClick?: () => void | undefined;
}

const observeClassList = (mutations: MutationRecord[]) => {
  mutations.forEach(mutation => {
    const navLink = mutation.target as HTMLLinkElement;
    const parent = navLink.parentNode as HTMLLIElement;
    if (navLink.classList.contains('active')) {
      parent.setAttribute('active', String(true));
    } else {
      parent.setAttribute('active', String(false));
    }
  });
};

export const Item: React.FC<Props> = ({ color, icon, name, to, onClick }) => {
  const { t } = useTranslation();
  const navLink = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (navLink.current !== null && navLink.current.classList.contains('active')) {
      navLink.current.parentElement!.setAttribute('active', String(true));
    }
    const observer = new MutationObserver(observeClassList);
    observer.observe(navLink.current!, { attributes: true, attributeFilter: ['class'] });
    return () => {
      observer.disconnect();
    };
  }, []);

  const className = useMemo(
    () =>
      css`
        &[active='true'] {
          color: var(${color});
        }
      `,
    [color],
  );

  return (
    <NavItem className={className} data-color={color}>
      <NavLink exact innerRef={navLink} to={to} onClick={onClick}>
        <span className="item">
          {icon}
          <label>{name || t('unnamedList')}</label>
        </span>
      </NavLink>
    </NavItem>
  );
};
