import { css } from 'emotion';
import React from 'react';

const styles = css`
  display: flex;
  width: 100%;
  padding: var(--spacing-unit-2) var(--spacing-unit-3);
  font-weight: bold;
  font-size: 1.2em;
`;

export const DialogTitle: React.FC = ({ children }) => <div className={styles}>{children}</div>;
