import { css } from 'emotion';
import React from 'react';

const styles = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: auto;
`;

export const CalendarHeader: React.FC = ({ children }) => <div className={styles}>{children}</div>;
