import { Scale } from 'components/Motion/Scale';
import { withPortal } from 'components/Portal/withPortal';
import { breakpoints } from 'constant';
import { css } from 'emotion';
import React from 'react';
import { Fab } from './Fab';

const styles = css`
  position: absolute !important;
  bottom: var(--spacing-unit);
  right: var(--spacing-unit);
  z-index: 9;
  height: 56px;
  width: 56px;

  @media (min-width: ${breakpoints.md}px) {
    bottom: var(--spacing-unit-2);
    right: var(--spacing-unit-2);
  }
`;

interface Props {
  onClick?: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined) | (() => void);
}

const Base = React.forwardRef((props: Props, ref: any) => {
  return (
    <Scale className={styles}>
      <Fab ref={ref} icon="delete" className={styles} {...props} />
    </Scale>
  );
});

Base.displayName = 'DeleteButtonBase';

type DeleteButtonType = React.ForwardRefExoticComponent<Props>;
// @ts-ignore
export const DeleteButton: DeleteButtonType = withPortal('root')(Base);
