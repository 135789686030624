import { Nav } from 'components/Nav/Nav';
import { ScrollView } from 'components/ScrollView/ScrollView';
import { css } from 'emotion';
import { AnimatePresence, motion } from 'framer-motion';
import { useDimensions } from 'hooks/use-dimensions';
import React, { useRef } from 'react';
import { SidebarBackdrop } from './SidebarBackdrop';

interface Props {
  className?: string;
  handler: () => void;
  logo?: React.ReactNode;
  open: boolean;
}

const backgroundVariants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    opacity: 1,
    width: 300,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
      opacity: { duration: 0.1 },
      width: { duration: 0 },
    },
  }),
  closed: {
    clipPath: 'circle(22px at 30px 30px)',
    opacity: 0,
    width: 0,
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
      opacity: { delay: 0.6, duration: 0.3 },
      width: { duration: 0, delay: 1 },
    },
  },
};

const variants = {
  open: {
    width: 300,
    height: '100%',
    transition: {
      duration: 0,
      when: 'beforeChildren',
      width: { duration: 0 },
    },
  },
  closed: {
    width: 0,
    height: 100,
    transition: {
      duration: 0,
      when: 'afterChildren',
      width: { duration: 0, delay: 1 },
    },
  },
};

const styles = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: auto;
  z-index: 11;
  overflow: hidden;
  padding-top: 56px;
  overflow: hidden;
  > .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: background-color 100ms ease-out;
    background-color: var(--primary);
  },
`;

const hideScrollbars = css`
  .simplebar-scrollbar::before {
    background-color: transparent;
  }
`;

export const MobileSidebar: React.FC<Props> = ({ open, handler, ...props }) => {
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  return (
    <>
      <AnimatePresence>{open && <SidebarBackdrop id="sidebar-backdrop" onClick={handler} />}</AnimatePresence>
      <motion.aside
        initial={false}
        animate={open ? 'open' : 'closed'}
        custom={height}
        ref={containerRef}
        className={styles}
        variants={variants}
        {...props}
      >
        <motion.div className="background" variants={backgroundVariants} />
        <ScrollView className={open ? undefined : hideScrollbars}>
          <Nav handler={handler} />
        </ScrollView>
      </motion.aside>
    </>
  );
};
