import { breakpoints } from 'constant';
import { css, cx } from 'emotion';
import { motion } from 'framer-motion';
import React from 'react';

interface DialogContainerProps {
  children: React.ReactNode;
  className?: string;
}

const variants = {
  init: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 20,
  },
};

const styles = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 300px;
  max-width: calc(100% - var(--spacing-unit-3));
  max-height: calc(100% - var(--spacing-unit-3));
  background-color: var(--secondary);
  color: var(--text-color);
  box-shadow: var(--box-shadow);
  border-radius: 4px;
  z-index: 21;
  width: 100%;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}px) {
    max-width: 800px;
  }
`;

const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void | undefined => {
  e.stopPropagation();
};

export const DialogContainer: React.FC<DialogContainerProps> = ({ children, className, ...props }) => {
  return (
    <motion.div
      initial="init"
      animate="visible"
      exit="hidden"
      onClick={stopPropagation}
      className={cx(styles, className)}
      variants={variants}
      {...props}
    >
      {children}
    </motion.div>
  );
};
