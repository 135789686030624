import { ListEncodingError } from 'errors';
import { List } from 'types/List';
import { Task } from '../types/Task';

/**
 * @param {Array<string>} gradients
 * @param {HTMLElement} element
 * @param {Number} time
 */
export const animateBackground = (
  gradients: string[] = [],
  element: HTMLElement | null = document.body,
  time = 5000,
): void => {
  // @ts-ignore
  window.Gradientify().create(element, gradients, time);
};

/**
 * @return {String} color
 */
export const getRandomColor = (): string => {
  const color = Math.floor(Math.random() * 6);
  return color === 0 ? '--list-color' : `--list-color-${color + 1}`;
};

export const LIST_COLORS = ['#F9B008', '#FF6B00', '#C9233A', '#D830C8', '#05B9D2', '#FFAFAF'];

export const encodeList = (list: List): string => {
  return encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(list))));
};

export const decodeList = (str: string): List => {
  try {
    const list = JSON.parse(decodeURIComponent(atob(decodeURIComponent(str))));
    list.creationDate = new Date(list.creationDate);
    list.tasks = list.tasks.map((t: Task) => {
      t.creationDate = new Date(t.creationDate);
      if (t.deadline) {
        t.deadline = new Date(t.deadline);
      }
      return t;
    });
    return list;
  } catch (e) {
    throw new ListEncodingError(e.message);
  }
};

export const mergeLists = (listA: List, listB: List): List => {
  const tasks = listA.tasks;
  const newTasks = [];

  for (const task of listB.tasks) {
    const index = tasks.findIndex(t => t.id === task.id);
    if (index !== -1) {
      tasks[index] = { ...tasks[index], ...task };
    } else {
      newTasks.push(task);
    }
  }

  return { ...listA, ...listB, tasks: tasks.concat(newTasks) };
};

export const includesOneOf = (items: string[], container: string[]): boolean => {
  return items.some(item => container.includes(item.split('.').shift()!));
};
