import { css, cx } from 'emotion';
import React, { useCallback, useState } from 'react';
import { Dialog } from '../Dialog/Dialog';
import { DialogContent } from '../Dialog/DialogContent';
import { Calendar } from './Calendar/Calendar';
import { Input } from './Input';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  icon?: string;
  label: string;
  name: string;
  onFocus?: (e: React.FormEvent<HTMLInputElement>) => void | undefined;
  onChange?: (e: React.ChangeEvent<any>) => void;
  placeholder?: string;
  required?: boolean;
  value?: Date;
  autoFocus?: boolean;
}

const styles = css`
  cursor: pointer;

  input {
    cursor: pointer;
  }
`;

const dialogStyles = css`
  width: auto !important;
  min-width: 360px;
`;

export const DateInput: React.FC<Props> = ({ className, name, value, onChange, ...props }) => {
  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const onSelect = useCallback(
    (date?: Date) => {
      // @ts-ignore mock event basic structure
      onChange({ target: { name, value: date } });
      setOpen(false);
    },
    [onChange, name],
  );

  return (
    <>
      <Input
        {...props}
        name={name}
        value={value ? value.toLocaleDateString() : ''}
        className={cx(styles, className)}
        readOnly
        icon="insert_invitation"
        onFocus={props.disabled ? undefined : openDialog}
      />
      <Dialog id={name + '-dialog'} open={open} onClose={closeDialog} className={dialogStyles}>
        <DialogContent>
          <Calendar date={value} initialDate={value} onSelect={onSelect} />
        </DialogContent>
      </Dialog>
    </>
  );
};
