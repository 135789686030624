import { css } from 'emotion';
import React, { useEffect } from 'react';
import { animateBackground } from 'utils/utils';

const styles = css`
  height: 200px;
  width: 100%;
  position: relative;

  > #header-content {
    z-index: 3;
    position: relative;
    box-shadow: inset 0px -50px 100px 0px rgba(0, 0, 0, 0.6);
    height: 100%;
  }
`;

export const Header: React.FC = ({ children }) => {
  useEffect(() => {
    animateBackground(
      // @ts-ignore
      [
        'linear-gradient(to right top, #d16ba5, #c358a4, #b146a5, #9b36a7, #8029ab, #7223ac, #621eae, #4f1baf, #5216ae, #5411ae, #5709ad, #5900ac)',
        'linear-gradient(to right top, #9a00ed, #aa00d8, #b400c4, #b905b1, #ba1aa1, #c2148d, #c5187b, #c5226a, #c62750, #c13437, #b7421f, #a85000)',
        'linear-gradient(to right top, #9000ff, #8211f0, #7418e2, #671dd3, #5b1fc4, #681cbd, #7219b7, #7b17b0, #970dae, #b001ab, #c700a7, #dc00a3)',
        'linear-gradient(to left bottom, #b70982, #bb2176, #bb326c, #ba4165, #b74e60, #bf565c, #c55f58, #ca6854, #d87349, #e2803b, #e79028, #e7a205)',
      ],
      document.getElementById('header'),
    );
  }, []);

  return (
    <header id="header" className={styles}>
      <div id="header-content">{children}</div>
    </header>
  );
};
