import { IconButton } from '@rmwc/icon-button';
import { Ripple } from '@rmwc/ripple';
import { Checkbox } from 'components';
import { css, cx } from 'emotion';
import { AnimatePresence, motion } from 'framer-motion';
import { useLongPress } from 'hooks/use-long-press';
import React, { useCallback, useState } from 'react';
import { HideAt, ShowAt } from 'react-with-breakpoints';
import { Task } from 'types/Task';

interface Props {
  task: Task;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  onSelect: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
}

const styles = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-unit);
  user-select: none;
  border-radius: 4px;
  min-height: 48px;
  padding-right: var(--spacing-unit);
  font-size: 1.2rem;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    overflow: hidden;

    &.description {
      min-height: auto;
      margin-bottom: var(--spacing-unit);
      flex-wrap: wrap;
    }

    > label {
      padding: var(--spacing-unit) 0 var(--spacing-unit) var(--spacing-unit);
      width: 100%;
      user-select: none;

      &.done {
        text-decoration: line-through;
      }
    }

    > button {
      font-size: 18px;
      height: 40px;
      width: 40px;
      transition: transform ease-in 0.2s;

      &.reverse {
        transform: rotate(180deg);
      }
    }

    > p {
      margin-left: 50px;
      border-left: 3px solid var(--tertiary);
      padding: var(--spacing-unit);
      width: 100%;
      font-size: 0.9rem;
      opacity: 0.7;
      white-space: pre;

      &.deadline {
        font-size: 0.75rem;
        font-style: italic;
      }
    }
  }
`;

type BaseProps = React.FC<
  Props & {
    className?: string;
    onTouchStart?: (e: React.TouchEvent<any>) => void | undefined;
    onTouchEnd?: (e: React.TouchEvent<any>) => void | undefined;
    extended: boolean;
    onExtend?: (e: any) => void | undefined;
  }
>;

const variants = {
  enter: {
    height: 'auto',
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const Base: BaseProps = ({ task, onChange, onSelect, className, extended, onExtend, ...props }) => {
  return (
    <motion.li className={cx(styles, className)} {...props} layout="position">
      <div>
        <Checkbox id={task.id} name={task.id} key={task.id} checked={!!task.done} onChange={onChange} />
        <label htmlFor={task.id} className={task.done ? 'done' : undefined}>
          {task.subject}
        </label>
        <ShowAt breakpoint={'largeAndAbove'}>
          {(task.deadline || task.description) && (
            <IconButton className={extended ? 'reverse' : undefined} icon="keyboard_arrow_down" onClick={onExtend} />
          )}
        </ShowAt>
        <IconButton icon="edit" onClick={onSelect} data-id={task.id} />
      </div>
      <AnimatePresence>
        {extended && (task.description || task.deadline) && (
          <motion.div className="description" animate="enter" exit="exit" initial="exit" variants={variants}>
            {task.deadline && <p className="deadline">{task.deadline.toLocaleDateString()}</p>}
            {task.description && <p>{task.description}</p>}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.li>
  );
};

export const TaskItem: React.FC<Props> = props => {
  const [extended, setExtended] = useState<boolean>(true);
  const f = useCallback(() => {
    navigator.vibrate(30);
    setExtended(old => !old);
  }, [setExtended]);
  const touchProps = useLongPress(f);

  return (
    <>
      <HideAt breakpoint="largeAndAbove">
        <Ripple {...touchProps}>
          <Base {...props} extended={extended} />
        </Ripple>
      </HideAt>
      <ShowAt breakpoint="largeAndAbove">
        <Base {...props} {...touchProps} extended={extended} onExtend={f} />
      </ShowAt>
    </>
  );
};
