import { css, cx } from 'emotion';
import React from 'react';
import { CalendarCell } from './CalendarCell';

const styles = css`
  font-weight: 600;
  font-size: 1rem;
  color: var(--accent);
`;

interface Props {
  index: number;
}

export const CalendarDay: React.FC<Props> = ({ children, index }) => (
  <CalendarCell className={cx(styles)} index={index}>
    {children}
  </CalendarCell>
);
