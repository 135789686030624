import { Icon } from '@rmwc/icon';
import { css, cx } from 'emotion';
import React, { forwardRef, useCallback } from 'react';

interface InputContainerProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  icon?: string;
  label: string;
  readOnly?: boolean;
  required?: boolean;
}

const styles = css`
  display: flex;
  flex-direction: column;
  position: relative;

  .error {
    position: absolute;
    top: 56px;
    font-size: 0.9rem;
    line-height: 1.15rem;
    color: var(--error);
    padding: 0;
  }

  > .input-container {
    border: none;
    border-bottom: 1px solid;
    border-color: var(--text-color);
    transition: all ease-in 0.2s;
    padding: var(--spacing-unit) 0 4px;

    > .label-wrapper {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
      line-height: 1.15rem;
      transition: color ease-in 0.2s;
      color: var(--text-color);

      > .indicator {
        color: inherit;
      }
    }

    > .input-wrapper {
      display: flex;
      min-height: 24px;

      > input,
      > textarea {
        width: 100%;
        font: inherit;
        font-size: 1rem;
        line-height: 1.4rem;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        color: var(--text-color);
        outline: none;
        text-overflow: ellipsis;

        ::-webkit-datetime-edit,
        ::-webkit-datetime-edit-fields-wrapper,
        ::-webkit-datetime-edit-text,
        ::-webkit-datetime-edit-month-field,
        ::-webkit-datetime-edit-day-field,
        ::-webkit-datetime-edit-year-field,
        ::-webkit-inner-spin-button,
        ::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    }

    &:focus-within {
      border-color: var(--accent);

      > .label-wrapper {
        color: var(--accent);
      }
    }
  }
`;

const errorStyles = css`
  > .input-container {
    border-color: var(--error);

    > .label-wrapper > .indicator {
      color: var(--error);
    }
  }
`;

export const InputContainer = forwardRef(
  ({ children, className, error, icon, label, required }: InputContainerProps, ref: any) => {
    const hasError = !!error;

    const focus = useCallback(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, [ref]);

    return (
      <div className={cx(styles, hasError && errorStyles, className)} onClick={focus}>
        <div className="input-container">
          {!!label && (
            <div className="label-wrapper">
              {label}
              {required && <span className="indicator"> *</span>}
            </div>
          )}
          <div className="input-wrapper">
            {children}
            {icon && <Icon icon={icon} />}
          </div>
        </div>
        {hasError && <div className="error">{error}</div>}
      </div>
    );
  },
);

InputContainer.displayName = 'InputContainer';
