export const THIS_YEAR = +new Date().getFullYear();

// The current month starting from 1 - 12
// 1 => January, 12 => December
export const THIS_MONTH = +new Date().getMonth() + 1;

// Week days names and shortnames
export const WEEK_DAYS: { [k: string]: string } = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

// Calendar months names and shortnames
export const CALENDAR_MONTHS: { [k: string]: string } = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

// Pads a string value with leading zeroes(0) until length is reached
export const zeroPad = (value: number, length: number): string => {
  return `${value}`.padStart(length, '0');
};

//  Number days in a month for a given year from 28 - 31
export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR): number => {
  const months30 = [4, 6, 9, 11];
  const leapYear = year % 4 === 0;

  return month === 2 ? (leapYear ? 29 : 28) : months30.includes(month) ? 30 : 31;
};

export const getMonthFirstDate = (month = THIS_MONTH, year = THIS_YEAR): Date => {
  return new Date(`${year}-${zeroPad(month, 2)}-01`);
};

// First day of the month for a given year from 1 - 7
// 1 => Sunday, 7 => Saturday
export const getMonthFirstDateDay = (month = THIS_MONTH, year = THIS_YEAR): number => {
  return getMonthFirstDate(month, year).getDay();
};

// Checks if a value is a date - this is just a simple check
export const isDate = (date: Date): boolean => {
  const isDateType = Object.prototype.toString.call(date) === '[object Date]';
  const isValidDate = date && !Number.isNaN(date.valueOf());

  return isDateType && isValidDate;
};

// Checks if two date values are of the same month and year
export const isSameMonth = (date: Date, baseDate: Date = new Date()): boolean => {
  if (!(isDate(date) && isDate(baseDate))) {
    return false;
  }

  const basedateMonth = +baseDate.getMonth() + 1;
  const basedateYear = baseDate.getFullYear();

  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return +basedateMonth === +dateMonth && +basedateYear === +dateYear;
};

// Checks if two date values are the same day
export const isSameDay = (date: Date, baseDate: Date = new Date()): boolean => {
  if (!(isDate(date) && isDate(baseDate))) {
    return false;
  }

  const basedateDate = baseDate.getDate();
  const basedateMonth = +baseDate.getMonth() + 1;
  const basedateYear = baseDate.getFullYear();

  const dateDate = date.getDate();
  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return +basedateDate === +dateDate && +basedateMonth === +dateMonth && +basedateYear === +dateYear;
};

export const isBefore = (date: Date, baseDate: Date = new Date()): boolean => {
  if (!(isDate(date) && isDate(baseDate))) {
    return false;
  }

  return date.getFullYear() === baseDate.getFullYear() &&
    date.getMonth() === baseDate.getMonth() &&
    date.getDate() === baseDate.getDate()
    ? false
    : date < baseDate;
};

// Formats the given date as YYYY-MM-DD
// Months and Days are zero padded
export const getDateISO = (date: Date = new Date()): string => {
  if (!isDate(date)) {
    return '';
  }

  return [date.getFullYear(), zeroPad(+date.getMonth() + 1, 2), zeroPad(+date.getDate(), 2)].join('-');
};

// Gets the month and year before the given month and year
// For example: getPreviousMonth(1, 2000) => {month: 12, year: 1999}
// while: getPreviousMonth(12, 2000) => {month: 11, year: 2000}
export const getPreviousMonth = (month: number, year: number): { month: number; year: number } => {
  const prevMonth = month > 1 ? month - 1 : 12;
  const prevMonthYear = month > 1 ? year : year - 1;

  return { month: prevMonth, year: prevMonthYear };
};

// Gets the month and year after the given month and year
// For example: getNextMonth(1, 2000) => {month: 2, year: 2000}
// while: getNextMonth(12, 2000) => {month: 1, year: 2001}
export const getNextMonth = (month: number, year: number): { month: number; year: number } => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextMonthYear = month < 12 ? year : year + 1;

  return { month: nextMonth, year: nextMonthYear };
};

// Calendar builder for a month in the specified year
// Returns an array of the calendar dates.
// Each calendar date is represented as an array => [YYYY, MM, DD]

export const calendar = (month = THIS_MONTH, year = THIS_YEAR): string[][] => {
  // Get number of days in the month and the month's first day

  const monthDays = getMonthDays(month, year);
  const monthFirstDay = getMonthFirstDateDay(month, year);

  // Get number of days to be displayed from previous and next months
  // These ensure a total of 42 days (6 weeks) displayed on the calendar

  const daysFromPrevMonth = monthFirstDay - 1;

  // Get the previous and next months and years

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);

  // Get number of days in previous month
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

  // Builds dates to be displayed from previous month

  const prevMonthDates = [...new Array(Math.max(daysFromPrevMonth, 0))].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [String(prevMonthYear), zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });

  // Builds dates to be displayed from current month

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [String(year), zeroPad(month, 2), zeroPad(day, 2)];
  });

  // Builds dates to be displayed from next month

  const nextMonthDates = [...new Array(Math.max(35 - thisMonthDates.length - prevMonthDates.length, 0))].map(
    (n, index) => {
      const day = index + 1;
      return [String(nextMonthYear), zeroPad(nextMonth, 2), zeroPad(day, 2)];
    },
  );

  // Combines all dates from previous, current and next months
  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};
