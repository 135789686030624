import { css, cx } from 'emotion';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

interface BackdropProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: any) => void | undefined;
}

const styles = css`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
    },
  },
};

export const Backdrop: React.FC<BackdropProps> = ({ children, className, onClick, ...props }) => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClick) {
        onClick(event);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onClick]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      transition={{ duration: 0.2 }}
      className={cx(styles, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </motion.div>
  );
};
