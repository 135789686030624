import { css, cx } from 'emotion';
import React, { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

interface Props {
  children?: React.ReactNode;
  className?: string;
  scrollableNodeProps?: any;
}

const styles = css`
  height: 100%;
  width: 100%;
  
  .simplebar-scrollbar {
    width: 4px;
    &::before {
      background-color: rgba(0, 0, 0, 0.6);
      width: 4px;
    },
  },
`;

export const ScrollView = forwardRef(({ className, children, ...props }: Props, ref: any) => {
  return (
    <SimpleBar className={cx(className, styles)} ref={ref} {...props}>
      {children}
    </SimpleBar>
  );
});

ScrollView.displayName = 'ScrollView';
