import { Button } from 'components/Button/Button';
import { ColorCircle } from 'components/ColorCircle/ColorCircle';
import { Dialog } from 'components/Dialog/Dialog';
import { DialogActions } from 'components/Dialog/DialogActions';
import { DialogContent } from 'components/Dialog/DialogContent';
import { Input } from 'components/Input/Input';
import { css } from 'emotion';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LIST_COLORS } from 'utils';

interface Props {
  onNew: (name?: string, color?: string) => void;
  onCancel: () => void;
  open: boolean;
}

const styles = css`
  > label {
    display: inline-block;
    margin: var(--spacing-unit-3) auto;
    font-size: 0.9rem;
  }
  .color-picker {
    display: flex;
    justify-content: space-evenly;
    margin-top: var(--spacing-unit);
    margin-bottom: var(--spacing-unit-2);
  }
`;

export const ListCreationDialog: React.FC<Props> = ({ onNew, onCancel, open }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name: '',
      color: 0,
    },
    onSubmit: (values, { resetForm }) => {
      const colorValue = values.color === 0 ? '--list-color' : `--list-color-${values.color + 1}`;
      onNew(values.name, colorValue);
      resetForm();
    },
  });

  const cancel = useCallback(() => {
    onCancel();
    formik.resetForm();
  }, [onCancel, formik]);

  const handleColor = useCallback(
    (color: string): void => {
      const index = LIST_COLORS.findIndex(c => c === color);
      formik.setFieldValue('color', index, false);
    },
    [formik],
  );

  return (
    <Dialog id="new-list" title={t('newList')} open={open} onClose={cancel}>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="new-list-form" className={styles} autoComplete="off">
          <Input
            autoFocus
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
            label={t('listName')}
          />
          <label>Couleur de la liste</label>
          <div className="color-picker">
            {LIST_COLORS.map((color, i) => (
              <ColorCircle onClick={handleColor} color={color} key={color} selected={i === formik.values.color} />
            ))}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{t('cancel')}</Button>
        <Button type="submit" outlined form="new-list-form">
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
