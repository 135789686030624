import animationData from 'assets/lottie-yoga.json';
import { css } from 'emotion';
import { motion } from 'framer-motion';
import React from 'react';
import Lottie from 'react-lottie';

const variants = {
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

const loaderVariants = {
  init: { scale: 0.7 },
  enter: {
    scale: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const styles = css`
  height: calc(100vh - 275px);
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 600px;
    max-width: 100%;
  }
`;

export const EmptyList: React.FC = () => {
  const options = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <motion.div initial="exit" animate="enter" exit="exit" variants={variants} className={styles}>
      <motion.div animate="enter" initial="init" variants={loaderVariants}>
        <Lottie options={options} speed={0.4} />
      </motion.div>
    </motion.div>
  );
};
