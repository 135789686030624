import { Fade, MotionRedirect } from 'components';
import { ROUTES } from 'constant';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AllLists } from './All/Lists';
import { List } from './List/List';

export const ListsModule: React.FC = () => {
  const location = useLocation();
  return (
    <Fade>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location}>
          <Route path={ROUTES.LIST()} component={List} />
          <Route exact path={ROUTES.LISTS()} component={AllLists} />
          <MotionRedirect to={ROUTES.LISTS()} />
        </Switch>
      </AnimatePresence>
    </Fade>
  );
};
