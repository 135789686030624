import { IconButton } from '@rmwc/icon-button';
import { SimpleMenuSurface } from '@rmwc/menu';
import { css } from 'emotion';
import React from 'react';

interface Props {
  menu: ({ label: string; action: (arg: unknown) => void | undefined } | null)[];
}

const styles = css`
  background-color: var(--secondary);
  color: var(--text-color);
  width: 240px;

  & + button {
    color: #ffffff;
  }

  ul {
    padding: var(--spacing-unit) 0;

    > li {
      padding: var(--spacing-unit) var(--spacing-unit-2);
      user-select: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
    }
  }
`;

export const Menu: React.FC<Props> = ({ menu }) => {
  return (
    // @ts-ignore
    <SimpleMenuSurface className={styles} handle={<IconButton icon="more_vert" />}>
      <ul>
        {menu
          .filter(el => el !== null)
          .map((item, i) => (
            <li key={i} onClick={item?.action}>
              {item?.label}
            </li>
          ))}
      </ul>
    </SimpleMenuSurface>
  );
};
