import { css, cx } from 'emotion';
import React from 'react';

interface Props {
  className?: string;
}

const styles = css`
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-unit);
`;

export const CalendarActions: React.FC<Props> = ({ className, ...props }) => {
  return <div className={cx(styles, className)} {...props} />;
};
